export interface LocalStorageEntry {
  item: any;
  expires: number;
}

export function setLocalStorageItem(
  key: string,
  item: any,
) {
  try {
    const sevenDaysAfter = new Date().getTime() + (1000 * 60 * 60 * 24 * 7)
    const storageItem = {
      item,
      expires: sevenDaysAfter
    };
    localStorage.setItem(key, JSON.stringify(storageItem));
    // todo fix error type
  } catch (error: any) {
    console.error("[localStorage.setItem] error.message => ", error.message);
  }
}

export function getLocalStorageItem(key: string) {
  let ret = undefined;
  const localStorageEntryRaw = localStorage.getItem(key);
  if (localStorageEntryRaw) {
    const localStorageEntry: LocalStorageEntry =
      JSON.parse(localStorageEntryRaw);
    if (Date.now() < localStorageEntry.expires) {
      ret = localStorageEntry.item;
    }else{
      //remove token if its expired
      removeLocalStorageItem(key);
    }
  }
  return ret;
}

export function removeLocalStorageItem(key: string) {
  localStorage.removeItem(key);
}
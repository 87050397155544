<template>
  <v-snackbar
        v-model="status"
        :timeout="'4000'"
      >
        {{ message }}
  
        <template v-slot:action="{ attrs }">
          <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
</template>

<script>
import {  useStore } from 'vuex'
import { computed } from 'vue'
export default {
    name: 'SnackBar',
    setup(){
        const store = useStore()
        const message = computed(() => store.state.snackbarMessage)
        const status = computed(() => store.state.snackbarStatus)
        return {
            message, status,
        }
    }

}
</script>

<style>

</style>
import { initializeApp } from "firebase/app";
import FirebaseFirestore from '@firebase/firestore-types';
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

export const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  projectId: process.env.VUE_APP_PROJECTID,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPID,
  measurementId: process.env.VUE_APP_MEASUREMENTID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const registerApp = initializeApp(firebaseConfig, 'secondary');

export const firestore =  getFirestore(app);
export const firebaseAuth = getAuth(app);
export const registerFirebaseAuth = getAuth(registerApp);





export const converter = {
    toFirestore: (data: any) => data,
    fromFirestore: (snap: FirebaseFirestore.QueryDocumentSnapshot) =>
    snap.data() as any
}



<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
    <SnackBar />
    <div id="modals"></div>
  </v-app>
</template>

<script >
import { defineComponent } from 'vue'
import SnackBar from "@/components/SnackBar.vue"
import { mapActions } from 'vuex'
export default defineComponent({
  name: 'App',
  components: {
      SnackBar
  },
  data () {
    return {
      //
    }
  },
  methods: {
      //controller for user authentification
      ...mapActions(['authController'])
  },
  mounted() {
    this.authController()
  }
})
</script>

<style>
#app{
  color: black;
}
</style>

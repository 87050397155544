import { createStore, Store } from "vuex";
import { firestore, converter, firebaseAuth, registerFirebaseAuth } from "@/modules/firebase";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { sha256 } from "js-sha256";
import {
  setLocalStorageItem,
  getLocalStorageItem,
  removeLocalStorageItem,
} from "@/utils/localStorage";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  setDoc,
  Timestamp,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { InjectionKey } from "vue";
import { User } from "@/types/user";
import { DocumentSnapshot, DocumentReference } from "@firebase/firestore-types";
interface State {
  isLoading?: boolean;
  snackbarMessage: string;
  snackbarStatus: boolean;
  user: User;
  authData: any;
  allUsers: User[];
  allFeeds: any[];
}
export const key: InjectionKey<Store<State>> = Symbol();

export default createStore({
  state: {
    snackbarMessage: "",
    snackbarStatus: false,
    user: null as any,
    allUsers: [],
    authData: null as any,
    allFeeds: [] as any [],
  },
  mutations: {
    mutateSnackbarData(state, payload) {
      state.snackbarMessage = payload.message;
      state.snackbarStatus = payload.status;
    },
    setUserData(state, payload) {
      state.user = payload;
    },
    setUsersData(state, payload) {
      state.allUsers = payload;
    },
    setAuthData(state, payload) {
      state.authData = payload;
    },
    setAllFeeds(state, payload) {
      state.allFeeds = payload;
    }
  },
  actions: {
    //controller for control auth data and loaded user data
    async authController({commit, dispatch}){
        firebaseAuth.onAuthStateChanged(async function(user:any) {
          console.log('controller triggered')
          if (!user) { // not logged in
              commit('setAuthData', null);
              commit('setUserData', null);
              removeLocalStorageItem('cryptotoolstoken');
          }else{
            //if user is authentificated get user data and all users data if user is admin
              setLocalStorageItem('cryptotoolstoken', user.accessToken);
              commit('setAuthData', user);
              await dispatch("getAllFeeds");
              await dispatch("getUserData");
              await dispatch("getAllUsers");
          }
          console.log(user)
      });
    },

    //logout user(remove token and sign out from firebase)
    async logout({ commit }): Promise<void> {
      removeLocalStorageItem("cryptotoolstoken");
      signOut(firebaseAuth);
    },

    //show snackbar for 3 seconds
    showSnackbar({ commit }, payload) {
      console.log("show snackbar", payload);
      commit("mutateSnackbarData", payload);
      setTimeout(() => {
        commit("mutateSnackbarData", { message: "", status: false });
      }, 3000);
    },

    //get data of all users if user is admin
    async getAllUsers({ state, commit, dispatch }) {
      //check if current user available to request data
      return new Promise((resolve, reject) => {
        if (state.user && state.user.role === "admin") {
          const usersData: any = [];
          const usersRef = collection(firestore, "users");
          const q = query(usersRef);
          getDocs(q)
            .then((querySnapshot: any) => {
              if (querySnapshot && querySnapshot.docs.length > 0) {
                querySnapshot.docs.forEach((doc: DocumentSnapshot) => {
                  usersData.push(doc.data());
                });
              }
            })
            .then(() => {
              commit("setUsersData", usersData);
              resolve({ status: "success", data: usersData });
            });
        } else {
     
          reject({
            status: "error",
            message: "You are not authorized as admin",
          });
        }
      });
    },
    //Get all feeds from DB
    async getAllFeeds({commit}){
      const feed = collection(firestore, "feed");
      const q = query(feed);
      const result: any[] = [];
      getDocs(q)
      .then((querySnapshot: any) => {
        if (querySnapshot && querySnapshot.docs.length > 0) {
          querySnapshot.docs.forEach((doc: DocumentSnapshot) => {
            result.push(doc.data());
          });
        }
      }
      ).then(() => commit("setAllFeeds", result)).then(() => console.log(result));
      
    },
    //Add new feed and get all feeds
    addNewFeed({commit, dispatch}, payload){
      const feed = collection(firestore, "feed");
      const q: any = query(feed);
      setDoc(doc(q), payload)
      .then(() => {
        dispatch("getAllFeeds");
      })
    },
    async addNewUserWithLoginAndPassword({ state, commit, dispatch }, payload) {
      const email = payload.username + "@cryptotools.online";
      return new Promise((resolve, reject) => {
        createUserWithEmailAndPassword(
          registerFirebaseAuth,
          email,
          payload.password,
        )
          .then((user) => {
            if (user) {
            payload.uid = user.user.uid;
            dispatch('addUser', payload);
            }
          }).then(()=> {
            resolve({ status: "success", data: "User added" });
            signOut(registerFirebaseAuth)
            console.log(registerFirebaseAuth)
          })
          .catch((error) => {
            const catchedError = 
              { 
                status: "error", 
                message: "There was a problem creating the account." 
              }
            switch (error.code) {
              case "auth/invalid-email":
                catchedError.message = "The email address is badly formatted.";
                break;
              case "auth/email-already-exists":
                catchedError.message = "The email address is already in use.";
                break;
              case "auth/weak-password":
                catchedError.message = "The password is too weak.";
                break;
              default:
                catchedError.message = "There was a problem creating the account.";
                break;
            }
            reject(catchedError);
          });
        });
    },
    async addUser({ dispatch }, payload): Promise<any> {
      try {
        // check if user with current password already exists
        const usersRef = collection(firestore, "users");
        return new Promise((resolve, reject) => {
              const docData = {
                ...payload,
                timestamp: Timestamp.now(),
              };
              setDoc(doc(usersRef, payload.uid), docData);
              dispatch("showSnackbar", {
                message: "User created",
                status: true,
              });
              dispatch("getAllUsers");
              resolve({ status: "success", message: "User added" });
          
        });
      } catch (e) {
        console.log(e);
      }
    },
    async updateUser({ dispatch }, payload) {
      try {
        const currentUserRef = doc(firestore, "users", payload.uid);
        return new Promise((resolve, reject) => {
          updateDoc(currentUserRef, payload);
          dispatch("getAllUsers");
          dispatch("showSnackbar", { message: "User created", status: true });
          resolve({ status: "success", message: "User updated" });
        });
      } catch (e) {
        console.log(e);
      }
    },
    async deleteUser({ commit, dispatch }, uid) {
      try {
        const currentUserRef = doc(firestore, "users", uid);
        const resp = await deleteDoc(currentUserRef);
        await dispatch("getAllUsers");
        dispatch("");
        commit("mutateSnackbarData", { message: `User deleted`, status: true });
      } catch (e) {
        console.log(e);
      }
    },
    //login useing firebase auth
    loginAttemptWithLoginAndPassword({ commit, dispatch }, payload) {
      const email = payload.login + "@cryptotools.online";
      const password = payload.password;
      signInWithEmailAndPassword(firebaseAuth, email, password)
        .then((data: any) => {
          if (data.user.uid) {
            window.location.reload();
          }
        })
        .catch((error: any) => {
          switch (error.code) {
            case "auth/invalid-email":
              console.log("Invalid email");
              break;
            case "auth/user-not-found":
              console.log("No account with that email was found");
              break;
            case "auth/wrong-password":
              console.log("Incorrect password");
              break;
            default:
              console.log("Email or password was incorrect");
              break;
          }
        });
    },
    //get data for user with password hash if exists
    async getUserData({ state, commit }) {
      if (state.authData?.uid) {
        const usersRef = collection(firestore, "users");
        console.log('Make request for uid',state.authData.uid);
        const q = query(usersRef, where("uid", "==", state.authData.uid));
        const querySnapshot = await getDocs(q);
        if (querySnapshot && Array.from(querySnapshot.docs).length > 0) {
          const data = querySnapshot.docs[0].data();
          commit("setUserData", data);
        } else {
          commit("mutateSnackbarData", {
            message: `Error loading user data`,
            status: true,
          });
        }
      }
    },
  },

  getters: {
    snackbarMessage: (state) => state.snackbarMessage,
    snackbarStatus: (state) => state.snackbarStatus,
    user: (state) => state.user,
    allUsers: (state) => state.allUsers,
    authData: (state) => state.authData,
    allFeeds: (state) => state.allFeeds,
  },

  modules: {},
});

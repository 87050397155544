import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { getLocalStorageItem, LocalStorageEntry } from '@/utils/localStorage'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'HomePage',
    meta:{
      requireAuth: true,
    },
    component: () => import('../views/HomePage.vue')

  },
  {
    path: '/admin',
    name: 'AdminPage',
    meta:{
      requireAuth: true,
    },
    component: () => import('../views/AdminPage.vue')

  },
  {
    path: '/login',
    name: 'LoginPage',
    meta: {
      requireAuth: false
    },

    component: () => import(/* webpackChunkName: "about" */ '../views/LoginPage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireAuth)) {
    const userToken: LocalStorageEntry | undefined = getLocalStorageItem('cryptotoolstoken');
    if (!userToken) {
        next('/login')
    }else{
      next();
    }
  } else {
    next();
  }
})

export default router
